import React from "react";
import { GetServerSideProps } from "next";
import MetaContainer from "src/components/common/Layout/components/MetaContainer/MetaContainer";
import General from "src/components/General/General";
import { MetaDataPageType, SeoSchema } from "src/models/meta/getMetaData";
import { serverSideMetaRequest } from "src/utils/helpers/requestApi/serverSideMetaRequest";
import Cookies from "cookies";
import { getEconomyAnalogs } from "src/models/economy/getEconomyAnalogs";
import { DEFAULT_CITY } from "src/utils/constant/constant";
import { EconomyMainAnalogsSchema } from "src/models/economy/types";
import {
  getMainSpecialArticle,
  MainSpecialArticleSchema,
} from "src/models/articles/getSpecialArticle";
import {
  CommonArticlesSchema,
  getCommonArticles,
} from "src/models/articles/getCommonArticles";

export interface GeneralPageProps {
  aboutInfo?: EconomyMainAnalogsSchema;
  specialArticleData?: MainSpecialArticleSchema;
  commonArticleData?: CommonArticlesSchema[];
}

const GeneralPage: React.FC<SeoSchema & GeneralPageProps> = (props) => {
  return (
    <MetaContainer {...props}>
      <General {...props} />
    </MetaContainer>
  );
};
export const getServerSideProps: GetServerSideProps = async (context) => {
  const cookies = new Cookies(context.req, context.res);
  const city_slug = cookies.get("slug");

  const { data: aboutInfo } = await getEconomyAnalogs({
    city_slug: city_slug || DEFAULT_CITY,
    is_city_page: !!city_slug,
  }).catch((e) => e);

  const { data: specialArticleData } = await getMainSpecialArticle().catch(
    (e) => e
  );

  const { data: commonArticleData } = await getCommonArticles().catch((e) => e);

  const { props } = await serverSideMetaRequest(
    MetaDataPageType.main,
    context,
    city_slug
  );

  /*if (city_slug) {
    return {
      redirect: {
        destination: "/" + city_slug,
        permanent: false,
      },
    };
  }*/

  return {
    props: {
      ...props,
      isWithRedirect: true,
      isOpen: true,
      aboutInfo: aboutInfo || {},
      specialArticleData: specialArticleData || {},
      commonArticleData: commonArticleData || [],
    },
  };
};

export default GeneralPage;
